import React from "react"
import styled from "styled-components"
import { theme } from "Theme"
import { Helmet } from "react-helmet"
import Layout from "Layouts/default"
import Config from "Data"
import { Utils, SEO, ActionButton, LinkButtonAlt, ActionButtonAlt } from "Components"
import { graphql } from "gatsby"
import { Content, Title, Subtitle } from "Styles"
import { GatsbyImage } from "gatsby-plugin-image"
import Gap from "styles/Gap"

const FullWrapper = styled.section`
  display: grid;
  min-height: 260px;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 24px;
  justify-content: space-between;
  margin: 2rem 0;
  padding: 0 1rem;
  max-width: ${theme.layout.base};
  @media (min-width: ${theme.breakpoints.s}) {
    padding: 0 1rem;
  }

  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    margin: 2rem auto;
    width: 100%;
  }
`

const Wrapper = styled.section`
  color: ${({ mode }) => (mode === "dark" ? theme.colors.white.base : theme.colors.black.base)};
  background-color: ${({ mode }) => (mode === "dark" ? theme.colors.black.base : theme.colors.white.base)};
  border-color: ${({ mode }) => (mode === "light" ? theme.colors.white.base : theme.colors.black.base)};
  border-radius: ${theme.borderRadius.ui};
  overflow: hidden;
  box-shadow: ${theme.shadow.feature.small.default};
  z-index: 3;

  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
  max-width: ${theme.layout.base};
`

const FeaturedSection = ({
  track,
  dark,
  title,
  subtitle,
  image,
  children,
  cta_url1_text,
  cta_url1,
  cta_url2_text,
  cta_url2,
  cta_action1_text,
  cta_action1,
  cta_action2_text,
  cta_action2,
}) => {
  return (
    <Wrapper image={image}>
      {image && <GatsbyImage title={title} alt={""} image={image} dark={dark ? 1 : undefined} />}
      <Content style={{ textAlign: "center" }}>
        {title && title !== "null" && <Title className={!subtitle ? "mb-0" : ""}>{title}</Title>}
        {subtitle && subtitle !== "null" && <Subtitle className={!children ? "mb-0" : ""}>{subtitle}</Subtitle>}
        {children && children}
        {(cta_action1_text || cta_action2_text || cta_url1_text || cta_url2_text) && (
          <div style={{ marginTop: "1rem" }}>
            {cta_action1 && cta_action1_text && cta_action1_text !== "null" && (
              <ActionButton onClick={cta_action1} track={track} title={`${title} | ${cta_action1_text}`}>
                {cta_action1_text}
              </ActionButton>
            )}
            {cta_action2 && cta_action2_text && cta_action2_text !== "null" && (
              <ActionButtonAlt onClick={cta_action2} track={track} title={`${title} | ${cta_action2_text}`}>
                {cta_action2_text}
              </ActionButtonAlt>
            )}
            {cta_url1 && cta_url1_text && cta_url1_text !== "null" && (
              <LinkButtonAlt to={cta_url1} track={track} title={`${title} | ${cta_url1_text}`}>
                {cta_url1_text}
              </LinkButtonAlt>
            )}
            {cta_url2 && cta_url2_text && cta_url2_text !== "null" && (
              <LinkButtonAlt to={cta_url2} track={track} title={`${title} | ${cta_url2_text}`}>
                {cta_url2_text}
              </LinkButtonAlt>
            )}
          </div>
        )}
      </Content>
    </Wrapper>
  )
}

const OptionsWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`

const TextWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`

class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      params: null,
    }
  }

  componentDidMount() {
    const paramsObj = Utils.getWindowParamsObj()
    const params = Object.keys(paramsObj)
      .map((key) => key + "=" + paramsObj[key])
      .join("&")
    this.setState({
      params: params,
    })
  }

  render() {
    const { data } = this.props
    const inperson = data.inperson
    const webinar = data.webinar

    return (
      <Layout
        locale={"en-us"}
        region={"us"}
        simple={false}
        sticky={false}
        transparentHeader={false}
        transparentFooter={true}>
        <SEO
          pathname="/sponsorship/welcome?"
          title={`Sponsorship | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={``}
        />
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Gap />
        <Gap />
        <Gap />
        <Gap />
        <TextWrapper>
          <Title>What type of event are you sponsoring?</Title>
        </TextWrapper>
        <OptionsWrapper>
          <FullWrapper>
            {inperson && (
              <FeaturedSection
                dark={false}
                title={`In Person Events`}
                subtitle={``}
                image={inperson.edges[0].node.childImageSharp.gatsbyImageData || ""}
                cta_url1_text={`Start here`}
                cta_url1={`https://cxo.typeform.com/to/JeQOVs3H?${this.state.params}`}
                page={`Sponsorship Welcome`}
              />
            )}
            {webinar && (
              <FeaturedSection
                dark={false}
                title={`Online Webinars`}
                subtitle={``}
                image={webinar.edges[0].node.childImageSharp.gatsbyImageData || ""}
                cta_url1_text={`Start here`}
                cta_url1={`https://cxo.typeform.com/to/hwu80n?${this.state.params}`}
                page={`Sponsorship Welcome`}
              />
            )}
          </FullWrapper>
        </OptionsWrapper>
      </Layout>
    )
  }
}

export default Welcome

export const query = graphql`
  query WelcomeQuery {
    triangle: allFile(filter: { name: { eq: "triangle" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    webinar: allFile(filter: { extension: { regex: "/(jpg)/" }, name: { eq: "bg-webinars" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    inperson: allFile(filter: { extension: { regex: "/(jpg)/" }, name: { eq: "bg-inperson" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
